import { mapState } from "vuex";

export default {
  data: () => ({
    hovered: false,
    disabled: false,
    cancelDisable: false,
  }),
  mounted() {
    if (!this.model) this.cancelDisable = true;
    try {
      this.$refs.inputField.onMouseUp = this.onHoverSetDisabled;
    } catch {
      /* do nothing */
    }
  },
  computed: {
    project() {
      return this.projects.find(
        (project) => project.url === this.$route.params.project_id
      );
    },
    ...mapState("projects", ["projects"]),
  },
  methods: {
    onHoverSetDisabled() {
      if (this.hovered) return;
      this.hovered = true;
      this.disabled =
        !!(this.model && this.global) &&
        this.$refs?.inputField?.valid &&
        !this.cancelDisable;
    },
    projectInformationValue(field) {
      return this.project?.information?.params[field];
    },
  },
};
