<template>
  <div>
    <v-text-field
      ref="inputField"
      v-bind="$attrs"
      class="flex-grow-1"
      :label="$translation.t($attrs.label) || $translation.t(label)"
      v-model="model"
      outlined
      :readonly="disabled"
      :rules="rulesPerField"
      hide-details="auto"
      :class="{ 'input-disabled': disabled }"
    >
    </v-text-field>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import { mapGetters } from "vuex";
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "PhoneInputField",
  props: {
    value: String,
    fax: Boolean,
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    model: "",
  }),
  created() {
    if (this.global) this.model = this.phone ?? "";
  },
  mounted() {
    this.model = this.value ?? this.model;
  },
  computed: {
    rulesPerField() {
      return this.fax ? [] : [rules.required];
    },
    label() {
      return this.fax ? "Fax" : "Phone";
    },
    phone() {
      return this.projectInformationValue(this.fax ? "fax" : "phone");
    },
    ...mapGetters(["getSingleValue"]),
  },
  watch: {
    model(newPhone) {
      this.$emit("input", newPhone);
    },
    value(newValue) {
      this.model = newValue;
    },
    phone(newValue) {
      if (this.global) this.model = newValue;
    },
  },
};
</script>

<style scoped></style>
